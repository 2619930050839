var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('search-card',{on:{"clicked":(term) => _vm.getItems(term, this.$route.query.page)}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user_id",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
              name: 'dashboard-auth-user',
              params: { id: item.user_id },
              query: { parent_page: _vm.$route.query.page },
            })}}},[_vm._v(" "+_vm._s((item.user ? item.user.display_name : "N/A").toUpperCase())+" ")])]}},{key:"item.expire_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("formateDate")(item.expire_at))+" ")]}},{key:"item.amount",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$lang("inr", [item.amount.toString()]))+" ")]}},{key:"item.vcoin",fn:function({ item }){return [_vm._v(" "+_vm._s(item.data.vcoin)+" ")]}},{key:"item.gcoin",fn:function({ item }){return [_vm._v(" "+_vm._s(item.data.gcoin)+" ")]}},{key:"item.active",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","color":item.active ? 'green' : 'red'},on:{"click":function($event){$event.stopPropagation();return _vm.handleActiveToggle(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.active ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline"))])],1)]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","color":item.consumed ? 'red' : 'gray'},on:{"click":function($event){$event.stopPropagation();return _vm.handleConsumedToggle(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.consumed ? "mdi-cloud-check" : "mdi-cloud"))])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'dashboard-coupons-create',
              params: { id: item.id },
            })}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('query-page-redirect',{attrs:{"pagination":_vm.pagination}})],1),_c('fab-button',{attrs:{"icon":'mdi-plus',"color":"primary"},on:{"clicked":function($event){return _vm.$router.push({ name: 'dashboard-coupons-create' })}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }